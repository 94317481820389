var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.blog.data),function(item,index){return [_c('section',{key:'blog-' + index,attrs:{"id":"section-history","data-aos":"fade-in","data-aos-duration":"1000"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 equalHW padding0"},[_c('div',{staticClass:"history-image",style:({
                                background:
                                    'url(' +
                                    item.og_image +
                                    ') no-repeat top center #191b1b !important',
                                'background-size': 'cover !important'
                            })})]),_c('div',{staticClass:"col-lg-6 equalHW padding0"},[_c('div',{staticClass:"section specialities"},[_c('div',{staticClass:"section history"},[_c('div',{staticClass:"history-content alignVCenter"},[_c('div',{staticClass:"container-col"},[_c('h4',{staticClass:"white title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"voffset-30"}),_c('p',{domProps:{"innerHTML":_vm._s(item.lead)}}),_c('div',{staticClass:"voffset-40"}),_c('router-link',{staticClass:"theme-btn btn-style-one bt-orange bt-menu-reserve",attrs:{"to":{
                                                name: 'Article',
                                                params: { slug: item.slug }
                                            }}},[_vm._v("Tovább "),_c('span',[_c('img',{attrs:{"alt":"","src":"/img/demo/dark-version/particles/arrow.svg"}})])])],1)])])])])])])]),_c('div',{key:'blog-offset-' + index,staticClass:"voffset-131"})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }