<template>
    <div>
        <template v-for="(item, index) in blog.data">
            <section
                id="section-history"
                data-aos="fade-in"
                data-aos-duration="1000"
                v-bind:key="'blog-' + index"
            >
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6 equalHW padding0">
                            <div
                                class="history-image"
                                v-bind:style="{
                                    background:
                                        'url(' +
                                        item.og_image +
                                        ') no-repeat top center #191b1b !important',
                                    'background-size': 'cover !important'
                                }"
                            ></div>
                        </div>
                        <div class="col-lg-6 equalHW padding0">
                            <div class="section specialities">
                                <div class="section history">
                                    <div class="history-content alignVCenter">
                                        <div class="container-col">
                                            <!--<h4 class="small medium right light">Discover your taste</h4>-->
                                            <h4 class="white title">
                                                {{ item.title }}
                                            </h4>
                                            <div class="voffset-30"></div>
                                            <p v-html="item.lead"></p>
                                            <div class="voffset-40"></div>
                                            <router-link
                                                :to="{
                                                    name: 'Article',
                                                    params: { slug: item.slug }
                                                }"
                                                class="theme-btn btn-style-one bt-orange bt-menu-reserve"
                                                >Tovább
                                                <span
                                                    ><img
                                                        alt=""
                                                        src="/img/demo/dark-version/particles/arrow.svg"/></span
                                            ></router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="voffset-131" v-bind:key="'blog-offset-' + index"></div>
        </template>
    </div>
</template>

<script>
export default {
    name: "Blog",
    data() {
        return {
            blog: {
                data: []
            }
        };
    },
    beforeMount() {
        this.getBlog();
    },
    watch: {
        $route: {
            handler: function() {
                this.getBlog();
            },
            deep: true
        }
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    methods: {
        getBlog() {
            let page = 1;
            if (this.$route.query && this.$route.query.page) {
                page = this.$route.query.page;
            }

            this.$http
                .get(`${this.url}/blog/get-list?page=${page}`)
                .then(response => {
                    this.blog = response.data;
                });
        }
    }
};
</script>

<style scoped></style>
